import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { NavLink } from "react-router-dom";
import BlogHomebox from "./BlogHomebox";
import VideoForm from "./VideoForm";
import DataContent from "./DataContent";
import MetaData from "./MetaData";


export default function Home() {
  const Site_Url = "https://birthcertificate.co.in/";
  const BASE_URL = Site_Url + "admin/API/";



  // Banner Api
  const [bannerdata, setbannerdata] = useState([]);
  useEffect(() => {
    const AllBanner = async () => {
      const response = await fetch(`${BASE_URL}bannerAPI.php`);
      const jsonData = await response.json();
      setbannerdata(jsonData);
    };
    AllBanner();
  }, [BASE_URL]);

  //Testimonial API
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    const Allclient = async () => {
      const response = await fetch(`${BASE_URL}clientAPI.php`);
      const jsonData = await response.json();
      setclientdata(jsonData);
    };
    Allclient();
  }, [BASE_URL]);

  //whychoose API
  const [whyChoosedata, setwhyChoosedata] = useState([]);
  useEffect(() => {
    const AllwhyChoose = async () => {
      const response = await fetch(`${BASE_URL}whychooseAPI.php`);
      const jsonData = await response.json();
      setwhyChoosedata(jsonData);
    };
    AllwhyChoose();
  }, [BASE_URL]);

  //service API
  const [servicedata, setservicedata] = useState([]);
  useEffect(() => {
    const AllServices = async () => {
      const response = await fetch(`${BASE_URL}serviceAPI.php`);
      const jsonData = await response.json();
      setservicedata(jsonData);
    };
    AllServices();
  }, [BASE_URL]);

  // Content Api
  const [contentdata, setcontentdata] = useState([]);
  useEffect(() => {
    const AllContent = async () => {
      const response = await fetch(`${BASE_URL}contentAPI.php`);
      const jsonData = await response.json();
      setcontentdata(jsonData);
    };
    AllContent();
  }, [BASE_URL]);

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  // Blog API
  const [Blogs, setBlogsdata] = useState([]);
  useEffect(() => {
    const AllBlogs = async () => {
      const response = await fetch(`${BASE_URL}homeblogAPI.php`);
      const jsonData = await response.json();
      setBlogsdata(jsonData);
    };
    AllBlogs();
  }, [BASE_URL]);


 

  const options = {
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    navText: ["&lt;", ">"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const clientOwl = {
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    navText: ["&lt;", ">"],
    smartSpeed: 1000,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  

  return (
    <>
      <MetaData />

      {/* Slider Area */}
      <section className="home-slider">
        <OwlCarousel className="owl-theme" {...options}>
          <div className="item">
            <img
              src={bannerdata.home_slider1}
              alt={bannerdata.home_slider1_alt}
              title={bannerdata.home_slider1_title}
            />
          </div>
          <div className="item">
            <img
              src={bannerdata.home_slider2}
              alt={bannerdata.home_slider2_alt}
              title={bannerdata.home_slider2_title}
            />
          </div>
        </OwlCarousel>
      </section>
      {/* End Slider Area */}
      <VideoForm
        IframeVideo={webSetting.iframe_video}
        SectionTitle={webSetting.video_form_title}
      />
      {/* Content-1 Section Start */}

      {contentdata.title1 && (
        <DataContent
          title={contentdata.title1}
          desc={contentdata.description1}
        />
      )}

      {/* Content-1 Section End */}

      {/* About Section Start */}
      {/* <section className="about_section section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
            <div className="col-md-2">
              <div className="section_title">
                <h2 className="title text-dark">About</h2>
              </div>
            </div>
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="about_img_box">
                <img
                  src={webSetting.about_img}
                  className="about_img"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="about_text_box">
                <h1 className="about_title">{webSetting.about_title}</h1>
                <p
                  className="about_desc"
                  dangerouslySetInnerHTML={{
                    __html: webSetting.about_desc,
                  }}
                ></p>
                <NavLink to="/about" className="about_btn">
                  {webSetting.about_btn_name}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* About Section End */}

      {/* Services Section Start */}
      <section className="services_section section_padding service_bg section_padding_bottom section_margin_top">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-6">
              <div className="section_title">
                <h2 className="title text-light">{webSetting.service_title}</h2>
              </div>
            </div>
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <div className="mb-3 row">
            {servicedata.map((value, index) =>
              <div key={index} className="col-lg-2 col-md-4 col-sm-6 col-xs-6 mt-3">
                <NavLink to="/services">
                  <div className="sevice_box">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: value.icon,
                      }}
                    ></div>
                    <h4>{value.name}</h4>
                  </div>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Services Section End */}

      {/* Content-2 Section Start */}

      {contentdata.title2 && (
        <DataContent
          title={contentdata.title2}
          desc={contentdata.description2}
        />
      )}

      {/* Content-2 Section End */}

      {/* How Work Section Start */}
      <section className="howWork_section section_padding ">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title text-dark">How It Works</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="steps">
                <ul className="list-unstyled">
                  <div className="row">
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-hand-o-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 1</span>
                              <h5>Select the Service</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-share-square-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 2</span>
                              <h5>Send Inquiry</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-question"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 3</span>
                              <h5>Ask Question</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 4</span>
                              <h5>Send Document</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-money"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 5</span>
                              <h5>Pay Online</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li className="last_staps6">
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-hourglass-start"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 6</span>
                              <h5>Work Start</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="img_right_box">
                <img
                  src={webSetting.howwork_img}
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* How Work Section End */}

      {/* Content-3 Section Start */}
      {contentdata.title3 && (
        <DataContent
          title={contentdata.title3}
          desc={contentdata.description3}
        />
      )}

      {/* Content-3  Section End */}

      {/* Why Choose Us Section Start */}
      <section className="whyChoose_section section_padding service_bg section_padding_bottom section_margin_top">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-6">
              <div className="section_title">
                <h2 className="title text-light">
                  {webSetting.whyChoose_title}
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mt-3">
              <div className="head_img">
                <img
                  src={whyChoosedata.stap1_img}
                  className="whychoose_img"
                  alt="img"
                />
              </div>
              <div className="choose_one">
                <ul className="list-unstyled">
                  <li>
                    <span className="red">01</span>
                  </li>
                  <li>
                    <h4>{whyChoosedata.stap1_title}</h4>
                  </li>
                  <li>
                    <p>{whyChoosedata.stap1_tagline}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mt-3">
              <div className="head_img">
                <img
                  src={whyChoosedata.stap2_img}
                  className="whychoose_img mr_tp"
                  alt="img"
                />
              </div>
              <div className="choose_one">
                <ul className="list-unstyled">
                  <li>
                    <span className="red">02</span>
                  </li>
                  <li>
                    <h4>{whyChoosedata.stap2_title}</h4>
                  </li>
                  <li>
                    <p>{whyChoosedata.stap2_tagline}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mt-3">
              <div className="head_img">
                <img
                  src={whyChoosedata.stap3_img}
                  className="whychoose_img"
                  alt="img"
                />
              </div>
              <div className="choose_one">
                <ul className="list-unstyled">
                  <li>
                    <span className="red">03</span>
                  </li>
                  <li>
                    <h4>{whyChoosedata.stap3_title}</h4>
                  </li>
                  <li>
                    <p>{whyChoosedata.stap3_tagline}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mt-3">
              <div className="head_img">
                <img
                  src={whyChoosedata.stap4_img}
                  className="whychoose_img"
                  alt="img"
                />
              </div>
              <div className="choose_one">
                <ul className="list-unstyled">
                  <li>
                    <span className="red">04</span>
                  </li>
                  <li>
                    <h4>{whyChoosedata.stap4_title}</h4>
                  </li>
                  <li>
                    <p>{whyChoosedata.stap4_tagline}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Why Choose Us Section End */}

      {/* Content-4 Section Start */}

      {contentdata.title4 && (
        <DataContent
          title={contentdata.title4}
          desc={contentdata.description4}
        />
      )}

      {/* Content-4 Section End */}

      {/* Client Section Start */}
      <section id="client_section" className="section_padding client_section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title">
                <h2 className="title text-dark">Clients Had To Say</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <div className="slider">
            <OwlCarousel className="owl-theme" {...clientOwl}>
              {clientdata.map((value, index) =>
                <div key={index} className="slider-card item">
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <img src={value.image} className="imgs" alt="testo1" />
                  </div>
                  <div className="nameedu">
                    <h3 className="text-center">{value.name}</h3>
                    <p className="text-center">{value.designation}</p>
                  </div>
                  <p className="clientwords">{value.description}</p>
                </div>
              )}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* Client Section End */}

      {/* Content-5 Section Start */}

      {contentdata.title5 && (
        <DataContent
          title={contentdata.title5}
          desc={contentdata.description5}
        />
      )}

      {/* Content-5 Section End */}
      {/* Blog section Start */}
      <section className="blog_section  section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
            <div className="col-md-2">
              <div className="section_title">
                <h2 className="title text-dark">Blogs</h2>
              </div>
            </div>
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
          </div>
          <div className="row">
            {Blogs.map((value, index) =>
              <BlogHomebox
                key={index}
                slug={value.slug}
                lang={value.lang}
                image={value.sort_img}
                image_title={value.image_title}
                image_alt={value.image_alt}
                date={value.date}
                title={value.title}
                tagline={value.tagline}
              />
            )}
          </div>
        </div>
      </section>
      {/* Blog section End */}
    </>
  );
}
